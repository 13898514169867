import { HelpText } from '@etchteam/mobius';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function InputErrors({
  error,
}: {
  readonly error:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | Merge<FieldError, FieldError[]>;
}) {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  let message = t(`common.errors.${error.type}`);

  if (error.message) {
    message = error.message.toString();
  }

  return message ? <HelpText type="negative">{message}</HelpText> : null;
}

export default InputErrors;
